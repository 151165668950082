
import {stateModule, userModule, organisationDocumentsModule} from '@/store'
import {Component, Vue, Prop} from 'vue-property-decorator'
import {AuthUserResource} from '@/models/users/AuthUserResource'
import {DocumentPresetResource} from '@/models/documents/DocumentPresetResource'
import {DocumentPresetPatchRequest} from '@/requests/documents/DocumentPresetPatchRequest'
import {OrganisationDocumentPresetResource} from '@/models/organisationDocuments/OrganisationDocumentPresetResource'

import TextInput from '@/components/inputs/Text.vue'
import SwitchInput from '@/components/inputs/Switch.vue'
import DefaultModal from '@/components/modals/Default.vue'
import DropdownInput from '@/components/inputs/Dropdown.vue'
import ActionButtons from '@/components/buttons/ActionButtons.vue'
import PresetVersions from '@/components/preset/VersionsModal.vue'

@Component({
  components: {ActionButtons, DefaultModal, SwitchInput, DropdownInput, TextInput, PresetVersions}
})
export default class PresetActions extends Vue {

  @Prop()
  private readonly preset!: DocumentPresetResource | OrganisationDocumentPresetResource

  @Prop()
  private readonly isActive!: boolean

  @Prop()
  private countries!: SelectItem[]

  @Prop()
  private departments!: SelectItem[]

  private isEditing: boolean = false
  private isEditingModalOpen: boolean = false
  private editForm: DocumentPresetPatchRequest = new DocumentPresetPatchRequest({ preset: this.preset })
  private editErrors: ErrorResponse = {}

  private isDeleting: boolean = false
  private isDeletingModalOpen: boolean = false

  private isHistoryModalOpen: boolean = false

  private get user(): AuthUserResource | null {
    return userModule.user || null
  }

  private select() {
    this.$emit('select', this.preset)
  }

  private openEditPresetModal() {
    this.isEditingModalOpen = true
  }

  private closeEditPresetModal() {
    this.isEditingModalOpen = false
  }

  private async patchPreset() {
    this.isEditing = true

    try {
      await this.preset.patch(this.editForm)
      this.isEditingModalOpen = false
      stateModule.setNotification({
        message: `Preset '${this.editForm.name}' has been updated.`,
        type: 'success',
      })
      this.$emit('edited')
    } catch ({ errors }) {
      if (errors) {
        this.editErrors = errors
      }
    } finally {
      this.isEditing = false
    }
  }

  private restoredPreset(): void {
    this.$emit('restored')
  }

  private openDeleteModal() {
    this.isDeletingModalOpen = true
  }

  private closeDeleteModal() {
    this.isDeletingModalOpen = false
  }

  private async deletePreset() {
    this.isDeleting = true
    try {
      await this.preset.delete()
      this.user?.getPresets()
      organisationDocumentsModule.getPresets()
      this.$emit('deleted', this.preset)
      
    } catch ({ message }) {
      console.error(message)
    } finally {
      this.closeDeleteModal()
      this.isDeleting = false
    }
  }

  private openHistoryModal() {
    this.isHistoryModalOpen = true
  }

  private closeHistoryModal() {
    this.isHistoryModalOpen = false
  }
}
